import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(32.0, 32.0)">
        <path
          d="M0 16.0001V32H2.21538H4.43077L4.43881 20.663L4.44685 9.32601L4.98347 10.3979C5.27852 10.9874 7.61945 16.0878 10.1857 21.7321L14.8513 31.9944L16 31.9942L17.1487 31.9941L22.2359 20.7748L27.3231 9.55539L27.3653 20.7778L27.4074 32H29.7037H32V16.0045V0.00906839L29.6615 0.0107165L27.3231 0.0125307L21.6711 12.4567C18.5625 19.3011 15.9921 24.8738 15.9591 24.8406C15.9262 24.8075 13.3717 19.2254 10.2827 12.4359L4.66626 0.0915192L2.33305 0.0458427L0 0V16.0001Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="
        M 48, 48
        m -36, 0
        a 36,36 0 1,0 72,0
        a 36,36 0 1,0 -72,0
        "
      />
    </g>
  </svg>
);

export default IconLoader;
